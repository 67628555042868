/*
 * File: button.css
 * Project: our-wave-admin-app
 *
 * Created by Brendan Michaelsen on December 31, 2021 at 2:14 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: September 16, 2024 at 10:36 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Button Styles
 */

.dark-blue-cta {
	border-radius: 4px;
	font-size: 12px !important;
	white-space: nowrap;
	opacity: 1;
	padding: 16px 24px 16px 24px;
	display: inline-block;
}
.dark-blue-cta.solid {
	background-color: var(--wave-darker-blue);
	border: 2px solid var(--wave-darker-blue);
	color: white;
}
.dark-blue-cta.solid.transparent {
	color: var(--wave-lighter-blue);
}
.dark-blue-cta.solid.lighter-blue-transparent {
	color: var(--wave-lighter-blue);
}
.dark-blue-cta.solid.light-blue-transparent {
	color: var(--wave-light-blue);
}
.dark-blue-cta.solid.blue-transparent {
	color: var(--wave-blue);
}
.dark-blue-cta.outline {
	background-color: transparent;
	border: 2px solid var(--wave-darker-blue);
	color: var(--wave-darker-blue);
}
.dark-blue-cta:hover,
.dark-blue-cta:active,
.dark-blue-cta:disabled {
	opacity: 0.85;
	text-decoration: none !important;
}
.dark-blue-cta:disabled {
	opacity: 0.75;
	cursor: not-allowed;
}
.auth-button {
	margin-top: 25px;
	padding: 6px 0px 15px 0px;
	width: 82%;
}
