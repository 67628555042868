/*
 * File: card.css
 * Project: our-wave-admin-app
 *
 * Created by Brendan Michaelsen on December 31, 2021 at 2:14 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: September 16, 2024 at 10:36 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Card Element
 */

.card-container {
	display: flex;
	flex-wrap: wrap;
	margin: 15px -12px 0px;
}
.card-element {
	padding: 12px 12px 12px 12px;
	cursor: default;
	text-align: left;
	position: relative;
}
.card-element .inner {
	background-color: var(--wave-white);
	border-radius: 4px;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14);
	cursor: default;
	padding: 16px 16px 16px 16px;
	position: relative;
}
.card-element .inner .content-padding {
	padding-right: 10px;
	overflow: hidden;
}
.card-element.selectable .inner {
	cursor: pointer;
}
.card-element .tag {
	background-color: var(--wave-light-grey);
	padding: 3px 8px 3px 8px;
	border-radius: 4px;
	margin-bottom: 6px;
	display: inline-block;
}
.card-element .tag.prompt {
	text-align: center;
	width: 100%;
	margin: 12px 0px 0px;
}
.card-element .tag p {
	color: var(--wave-obfuscation-grey-text) !important;
	margin: 0px !important;
	font-size: 0.8rem !important;
	text-transform: none !important;
	opacity: 1 !important;
	font-weight: 600;
}
.card-element .card-action-container {
	display: flex;
	margin: 0px -16px -16px -16px;
}
.card-element .card-action-container button {
	flex-grow: 1;
	text-align: center;
	font-size: 0.7rem;
	color: var(--wave-white);
	padding: 14px 0px 14px;
	opacity: 1;
}
.card-element .card-action-container button:hover {
	opacity: 0.85;
}
.card-element .card-action-container button.primary-button {
	background-color: var(--wave-darker-blue);
}
.card-element .card-action-container button.secondary-button {
	background-color: var(--wave-button-grey);
}
.card-element .card-action-container button.left {
	border-bottom-left-radius: 4px;
}
.card-element .card-action-container button.right {
	border-bottom-right-radius: 4px;
}
.card-element span.category {
	font-size: 11px;
	background-color: var(--wave-darker-blue);
	padding: 5px 14px 5px 14px;
	border-radius: 26px;
	font-family: 'Outfit', Verdana, Arial, Helvetica, sans-serif;
	color: var(--wave-lighter-blue);
	margin-bottom: 8px;
	display: inline-block;
	font-weight: 800;
}
.card-element span.category.scope {
	background-color: var(--wave-blue);
}

/**
 * Card Sizing
 */

@media only screen {
	.card-element.size-3-column {
		width: 100%;
	}
	.card-element.size-4-column {
		width: 100%;
	}
	.card-element.size-6-column {
		width: 100%;
	}
	.card-element.size-8-column {
		width: 100%;
	}
}
@media only screen and (min-width: 40em) {
	.card-element.size-3-column {
		width: 50%;
	}
	.card-element.size-4-column {
		width: 50%;
	}
	.card-element.size-8-column {
		width: 50%;
	}
}
@media only screen and (min-width: 64em) {
	.card-element.size-3-column {
		width: 25%;
	}
	.card-element.size-4-column {
		width: 33.33333%;
	}
	.card-element.size-6-column {
		width: 50%;
	}
	.card-element.size-8-column {
		width: 66.66666%;
	}
}

/**
 * Card Options
 */

.card-options {
	position: absolute;
	top: 12px;
	right: 2px;
	z-index: 99;
	width: 30px;
	height: 30px;
	text-align: center;
	cursor: pointer;
}
.row-options {
	position: absolute;
	top: 32px;
	right: 2px;
	z-index: 99;
	width: 30px;
	height: 30px;
	text-align: center;
	cursor: pointer;
}
.card-options .icon {
	color: var(--wave-darker-blue);
	background-position: center;
	background-size: cover;
	margin: 0px;
	width: 16px;
	height: 16px;
	display: inline-block;
}
.card-options .icon:before {
	position: relative;
	font-family: 'Font Awesome 5 Pro';
	top: -3px;
	left: 0px;
	content: '\f142';
	font-size: 1.25rem;
	font-weight: 400;
}
.card-options .icon.submission {
	margin-top: 7px;
}
.row-options .icon {
	color: var(--wave-darker-blue);
	background-position: center;
	background-size: cover;
	margin: 0px;
	width: 16px;
	height: 16px;
	display: inline-block;
}
.row-options .icon:before {
	position: relative;
	font-family: 'Font Awesome 5 Pro';
	top: -3px;
	left: 0px;
	content: '\f142';
	font-size: 1.25rem;
	font-weight: 400;
}
.card-options .options-dropdown {
	position: absolute;
	background-color: var(--wave-white);
	min-width: 190px;
	box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.22);
	z-index: 99;
	top: 30px;
	height: 0px;
	right: 0px;
	border-radius: 4px;
	overflow: hidden;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	padding: 0px;
	text-align: left;
}
.card-options:hover .dropdown-1,
.card-options:active .dropdown-1 {
	height: 32px;
}
.card-options:hover .dropdown-2,
.card-options:active .dropdown-2 {
	height: 64px;
}
.card-options:hover .dropdown-3,
.card-options:active .dropdown-3 {
	height: 96px;
}
.card-options:hover .dropdown-4,
.card-options:active .dropdown-4 {
	height: 128px;
}
.card-options:hover .dropdown-5,
.card-options:active .dropdown-5 {
	height: 160px;
}
.card-options:hover .dropdown-7,
.card-options:active .dropdown-7 {
	height: 224px;
}
.card-options:hover .dropdown-8,
.card-options:active .dropdown-8 {
	height: 256px;
}
.card-options .options-dropdown.hide-dropdown {
	height: 0px !important;
}
.card-options .dropdown-option,
.row-options .dropdown-option {
	font-family: 'Outfit', Verdana, Arial, Helvetica, sans-serif;
	font-size: 12px;
	padding: 7px 12px 7px;
	color: var(--wave-darker-blue);
	background-color: var(--wave-white);
	text-transform: none;
	line-height: 18px;
	letter-spacing: normal;
	font-weight: 600;
}
.card-options .dropdown-option.destructive,
.row-options .dropdown-option.destructive {
	color: var(--wave-red);
}
.card-options .dropdown-option:hover,
.row-options .dropdown-option:hover {
	background-color: var(--wave-light-grey);
}
