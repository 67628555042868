/*
 * File: full.css
 * Project: our-wave-admin-app
 *
 * Created by Brendan Michaelsen on December 31, 2021 at 2:14 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: April 10, 2024 at 1:40 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Element Styling
 */

html {
	height: 100%;
}
body {
	min-height: 100%;
	display: flex;
	flex-direction: column;
}
