/*
 * File: legal.css
 * Project: our-wave-admin-app
 *
 * Created by Brendan Michaelsen on December 31, 2021 at 2:14 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: December 17, 2024 at 2:28 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Media Queries
 */

@media only screen {
	.legal-text-content {
		padding-top: 20px;
		padding-bottom: 50px;
	}
	table.settings-table {
		font-size: 0.775rem;
	}
	.settings-table td,
	.settings-table th,
	table.settings-table td,
	table.settings-table th {
		padding: 0.8rem;
	}
}
@media only screen and (min-width: 25.563em) {
	table.settings-table {
		font-size: 1em;
	}
	.settings-table td,
	.settings-table th,
	table.settings-table td,
	table.settings-table th {
		padding: 0.9rem;
	}
}
@media only screen and (min-width: 43.9375em) {
	.legal-text-content {
		padding-top: 20px;
		padding-bottom: 80px;
	}
	.nav-container .menu-arrow.hide,
	.legal-nav-small-holder .menu-arrow.hide {
		opacity: 0;
	}
}

/**
 * Component Styling
 */

.legal-nav-small-holder .menu-arrow-container {
	width: 17px;
	height: 17px;
	float: right;
	position: relative;
	top: 0px;
}
.legal-nav-small-holder .menu-arrow {
	background-position: center;
	background-size: cover;
	position: absolute;
	top: 0px;
	opacity: 1;
	width: 100%;
	height: 100%;
	color: var(--wave-darker-blue);
}
.legal-nav-small-holder .menu-arrow:before {
	position: relative;
	font-family: 'Font Awesome 5 Pro';
	top: -2px;
	left: 0px;
	content: '\f078';
	font-size: 1.25rem;
	font-weight: 400;
}
.legal-nav-small-holder .menu-arrow.close {
	background-position: center;
	background-size: cover;
	position: absolute;
	top: 0px;
	opacity: 1;
	width: 100%;
	height: 100%;
	color: var(--wave-darker-blue);
}
.legal-nav-small-holder .menu-arrow.close:before {
	position: relative;
	font-family: 'Font Awesome 5 Pro';
	top: -2px;
	left: 0px;
	content: '\f077';
	font-size: 1.25rem;
	font-weight: 400;
}
.legal-nav-small-holder .nav-container .menu-arrow {
	width: 100%;
	height: 100%;
	display: block;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
}
.legal-text-content {
	border-top: 1px solid var(--wave-border-grey);
}
.legal-side-navigation {
	padding-left: 0px;
	height: 100%;
}
.legal-side-navigation h4 {
	font-size: 1rem;
	font-weight: 600;
	margin: 22px 0px 17px;
	color: var(--wave-darker-blue);
}
.legal-small-menu {
	padding-top: 25px;
	padding-bottom: 20px;
	border-top: 1px solid var(--wave-border-grey);
}
.legal-small-menu h4 {
	font-size: 16px;
	font-weight: 600;
	margin: 22px 0px 15px;
	color: var(--wave-darker-blue);
}
.legal-side-navigation h4:first-child {
	margin: 20px 0px 17px 0px;
}
.legal-small-menu h4:first-child {
	margin: 0px 0px 15px 0px;
}
.legal-side-navigation a,
.legal-side-navigation a:link,
.legal-side-navigation a:visited {
	font-family: 'Outfit', Verdana, Arial, Helvetica, sans-serif;
	display: block;
	font-size: 0.9rem;
	padding-bottom: 18px;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	color: var(--wave-darker-blue);
	letter-spacing: normal;
	text-transform: initial;
	margin-right: 50px;
	position: relative;
	margin-top: 3px;
	font-weight: 400;
}
.legal-small-menu a,
.legal-small-menu a:link,
.legal-small-menu a:visited {
	font-family: 'Outfit', Verdana, Arial, Helvetica, sans-serif;
	display: block;
	font-size: 15px;
	padding-bottom: 18px;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	color: var(--wave-darker-blue);
	text-transform: none;
	letter-spacing: normal;
	position: relative;
	font-weight: 400;
}
.legal-side-navigation a:hover,
.legal-small-menu a:hover {
	color: var(--wave-darker-blue);
}
.legal-side-navigation span,
.legal-small-menu span {
	z-index: 2;
	position: relative;
}
.legal-side-navigation .link-active span.highlight,
.legal-small-menu .link-active span.highlight {
	background-color: var(--wave-white);
	border-radius: 5px;
	color: var(--wave-darker-blue);
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 1;
	top: -10px;
	left: -11px;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
}
.legal-small-menu .link-active span.highlight {
	top: -11px;
}
.legal-nav-small-holder {
	margin-top: 30px;
	border-bottom: 1px solid var(--wave-border-grey);
	cursor: pointer;
}
.legal-nav-small {
	display: inline-block;
	width: 100%;
	margin: 0px 0px -6px;
	padding: 22px 16px 16px;
	background-color: var(--wave-white);
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
	border-radius: 5px;
}
.legal-nav-small-holder h3 {
	float: left;
	font-size: 1.175rem !important;
	margin: 0px !important;
	font-weight: 500;
	color: var(--wave-darker-blue);
	max-width: 83%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	letter-spacing: normal;
	text-transform: none !important;
}
.legal-nav-small-holder img {
	float: right;
	width: 20px;
	height: 20px;
	margin-top: 2px;
}
.toggle-legal-menu {
	padding: 0px 0px 0px 16px;
	display: none;
}
.toggle-legal-menu.show {
	display: block;
}
.legal-text-content-inner {
	margin-top: 15px;
}
table.settings-table {
	border-collapse: collapse;
	border-spacing: 0;
	display: table;
	box-sizing: border-box;
	margin-top: 1.375rem;
	color: var(--wave-darker-blue);
}
.settings-table thead {
	background-color: var(--wave-darker-blue);
	display: table-header-group;
	vertical-align: middle;
	border-color: inherit;
	color: var(--wave-white);
}
.settings-table tbody,
.settings-table tbody tr {
	background-color: var(--wave-white) !important;
	color: var(--wave-darker-blue);
}
.settings-table tr:first-child,
table.settings-table tr:first-child {
	border-top: 0.0625em solid var(--wave-table-border);
}
.settings-table tr,
table.settings-table tr {
	border-bottom: 0.0625em solid var(--wave-table-border) !important;
}
.settings-table tr {
	display: table-row;
	vertical-align: inherit;
	border-color: inherit;
	color: var(--wave-darker-blue);
}
.settings-table tr p {
	font-size: 1em;
}
.settings-table tr p:last-child {
	margin: 0px;
}
.settings-table td:first-child,
.settings-table th:first-child,
table.settings-table td:first-child,
table.settings-table th:first-child {
	border-left: 0.0625em solid var(--wave-table-border);
}
.settings-table td,
.settings-table th,
table.settings-table td,
table.settings-table th {
	border-right: 0.0625em solid var(--wave-table-border);
	color: var(--wave-darker-blue);
}
.settings-table th,
table.settings-table th {
	text-align: left;
	font-weight: 700;
	color: var(--wave-white);
	border: 0.0625em solid var(--wave-darker-blue) !important;
}
.settings-table th.nowrap,
table.settings-table th.nowrap {
	white-space: nowrap;
}
.settings-table th.padding,
table.settings-table th.padding {
	padding-right: 40px;
}
.settings-table tbody {
	display: table-row-group;
	vertical-align: middle;
	border-color: inherit;
	color: var(--wave-darker-blue);
}
.legal-settings-container {
	margin-top: 50px;
	border-radius: 8px;
	background-color: var(--wave-lighter-blue);
	padding: 1.975rem;
}
.legal-settings-container h2 {
	padding-bottom: 5px;
}
.legal-settings-row {
	margin-top: 30px;
	display: flex;
	align-items: center;
}
.legal-settings-row h3 {
	margin-bottom: 4px;
	font-size: 1.2rem !important;
}
.legal-settings-row p {
	margin: 0px;
	font-size: 0.9rem;
	line-height: 1.6;
}
.legal-settings-row .flex-right {
	width: 250px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
.legal-settings-row .custom-checkbox {
	box-shadow: none;
	border: 2px solid var(--wave-button-grey);
	background-color: var(--wave-lighter-blue);
	margin: 0px;
}
.legal-settings-row .custom-checkbox:focus,
.legal-settings-row .custom-checkbox:active,
.legal-settings-row .custom-checkbox.active {
	border: 2px solid var(--wave-darker-blue);
}
.general-content-section h2 {
	color: var(--wave-darker-blue);
	font-size: 1.8rem;
	margin-bottom: 15px;
}
.general-content-section h3 {
	text-transform: uppercase;
	font-size: 0.75rem !important;
	letter-spacing: 0.7px;
	margin-bottom: 18px !important;
	margin-top: 0px !important;
	color: var(--wave-darker-blue);
	text-align: left !important;
}
.general-content-section h4 {
	color: var(--wave-darker-blue);
	font-size: 1.2rem;
	margin-bottom: 10px;
}
.general-content-section p,
.general-content-section li {
	color: var(--wave-darker-blue);
	font-size: 1.07rem;
}
.general-content-section a,
.general-content-section a:link,
.general-content-section a:visited {
	text-transform: uppercase;
	font-size: 0.7rem;
	margin-top: 22px;
	color: var(--wave-darker-blue);
}
.general-content-section a:hover,
.general-content-section a:active {
	opacity: 0.75;
}
.general-content-section ul,
.general-content-section ol,
.general-content-section li {
	color: var(--wave-darker-blue);
}
.general-content-section li {
	margin-bottom: 15px;
}
.general-content-section ul,
.general-content-section ol {
	margin-bottom: 16px;
}
.general-content-section li .top-margin {
	margin-top: 10px;
	display: inline-block;
}
.general-content-section li.top {
	margin-top: 10px;
}
.emphasis-description-container {
	background-color: var(--wave-white);
	padding: 12px 18px;
	border-radius: 8px;
	margin-bottom: 25px;
}
.emphasis-description-container p {
	margin: 0px;
}
.emphasis-holder {
	background-color: var(--wave-darker-blue);
	color: var(--wave-white);
	border-radius: 4px;
	padding: 6px 10px 6px;
}
.emphasis-holder.number {
	padding: 4px 10px 4px;
}
