/*
 * File: app.css
 * Project: our-wave-admin-app
 *
 * Created by Brendan Michaelsen on December 31, 2021 at 2:14 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: September 16, 2024 at 11:29 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Core Imports
 */

@import url(colors.css);

/**
 * Element Imports
 */

@import url(components/elements/button.css);
@import url(components/elements/card.css);
@import url(components/elements/footer.css);
@import url(components/elements/header.css);
@import url(components/elements/input.css);
@import url(components/elements/modal.css);

/**
 * Component Imports
 */

@import url(components/pages/legal.css);

/**
 * Element Styling
 */

body,
html {
	background: white;
	overflow-x: hidden;
	margin: 0px;
}
h1 {
	font-family: 'Playfair Display', Georgia, Times, serif;
	font-weight: 900;
	color: var(--wave-darker-blue);
	line-height: 1.3;
}
h2,
h3,
h4,
h5,
h6,
a,
a:link,
a:visited,
button {
	font-family: 'Outfit', Verdana, Arial, Helvetica, sans-serif;
	font-weight: 800;
	color: var(--wave-darker-blue);
	line-height: 1.3;
}
p,
li,
tr,
th,
td {
	font-family: 'Outfit', Verdana, Arial, Helvetica, sans-serif;
	font-weight: 400;
	line-height: 180%;
	color: var(--wave-darker-blue);
}
input,
select,
textarea,
label {
	font-family: 'Outfit', Verdana, Arial, Helvetica, sans-serif !important;
	font-weight: 500 !important;
	color: var(--wave-darker-blue);
}
input {
	-webkit-appearance: none;
	color: var(--wave-darker-blue);
}
a,
button,
select {
	cursor: pointer;
}
a,
button {
	letter-spacing: 0.7px;
	text-transform: uppercase;
}
p > a {
	letter-spacing: normal;
}
@media only screen {
	h2 {
		font-size: 1.6rem;
	}
}
@media only screen and (min-width: 30.563em) {
	h2 {
		font-size: 1.6rem;
	}
}
@media only screen and (min-width: 40em) {
	h2 {
		font-size: 2.2rem;
	}
}
@media only screen and (min-width: 57.563em) {
	h2 {
		font-size: 2.2rem;
	}
}

/**
 * General Styling
 */

.animate {
	transition: all 0.3s;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-o-transition: all 0.3s;
}
.preload * {
	transition: none !important;
	-webkit-transition: none !important;
	-moz-transition: none !important;
	-ms-transition: none !important;
	-o-transition: none !important;
}
.left-align {
	text-align: left;
}
.center-align {
	text-align: center;
}
.right-align {
	text-align: right;
}
input:focus {
	outline: none;
	border: none;
}
button:focus {
	outline: none;
	text-transform: uppercase;
}
.hide-element {
	display: none;
}
.hide-element-important {
	display: none !important;
}
.transparent-element {
	opacity: 0;
}
.invisible-element {
	visibility: hidden;
}
.solid-element {
	opacity: 1 !important;
}
.no-margin {
	margin: 0px !important;
}
.flex-spacer {
	flex-grow: 1;
}
.full-width {
	width: 100%;
}
.underline {
	text-decoration: underline;
}
.pointer {
	cursor: pointer;
}
.italic {
	font-style: italic;
}

/**
 * Section Styling
 */

.yellow-gradient-bg-section {
	background: rgb(243, 211, 168);
	background: -moz-linear-gradient(135deg, rgba(243, 211, 168, 1) 0%, rgba(246, 234, 188, 1) 100%);
	background: -webkit-linear-gradient(135deg, rgba(243, 211, 168, 1) 0%, rgba(246, 234, 188, 1) 100%);
	background: linear-gradient(135deg, rgba(243, 211, 168, 1) 0%, rgba(246, 234, 188, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f3d3a8",endColorstr="#f6eabc",GradientType=1);
	position: relative;
}
.darker-blue-bg-section {
	background-color: var(--wave-darker-blue);
	position: relative;
}
.dark-blue-bg-section {
	background-color: var(--wave-dark-blue);
	position: relative;
}
.blue-bg-section {
	background-color: var(--wave-blue);
	position: relative;
}
.light-blue-bg-section {
	background-color: var(--wave-light-blue);
	position: relative;
}
.lighter-blue-bg-section {
	background-color: var(--wave-lighter-blue);
	position: relative;
}
.transparent-bg-section {
	background-color: transparent;
}

/**
 * Structure Styling
 */

.full-page-content {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}
@media only screen {
	.screen-overall-padding {
		padding-left: 5vw;
		padding-right: 5vw;
	}
}
@media only screen and (min-width: 30.563em) {
	.screen-overall-padding {
		padding-left: 5vw;
		padding-right: 5vw;
	}
}
@media only screen and (min-width: 57.563em) {
	.screen-overall-padding {
		padding-left: 4vw;
		padding-right: 4vw;
	}
}
@media only screen and (min-width: 75.563em) {
	.screen-overall-padding {
		padding-left: 4vw;
		padding-right: 4vw;
	}
}

/**
 * General Element Styling
 */

.right-arrow {
	color: var(--wave-darker-blue);
	background-size: contain;
	background-repeat: no-repeat;
	height: 24px;
	width: 24px;
	display: inline-block;
	position: relative;
	top: 8px;
	left: 7px;
}
.right-arrow:before {
	position: relative;
	font-family: 'Font Awesome 5 Pro';
	top: -4px;
	left: 0px;
	content: '\f30b';
	font-size: 1.35rem;
	font-weight: 400;
}
.left-arrow {
	color: var(--wave-darker-blue);
	background-size: contain;
	background-repeat: no-repeat;
	height: 24px;
	width: 24px;
	display: inline-block;
	position: relative;
}
.left-arrow:before {
	position: relative;
	font-family: 'Font Awesome 5 Pro';
	top: 1px;
	left: 0px;
	content: '\f30a';
	font-size: 1.35rem;
	font-weight: 400;
}
.left-arrow.grey {
	color: var(--wave-grey-text);
}
.right-arrow-holder {
	float: right;
	margin: 0px !important;
}
.header-content-section h3 {
	text-transform: uppercase;
	font-size: 0.8rem;
	margin-bottom: 14px;
}
.header-content-section h4 {
	margin-top: 15px;
	margin-bottom: 15px;
	line-height: 1.5;
}
.header-content-section p {
	font-size: 1.4rem;
	line-height: 160%;
	margin-bottom: 20px;
	margin-top: 15px;
}
.header-content-section h4 a,
.header-content-section h4 a:link,
.header-content-section h4 a:visited {
	text-transform: none;
	text-decoration: underline;
}
.header-content-section p a,
.header-content-section p a:link,
.header-content-section p a:visited {
	font-family: inherit;
	text-transform: none;
	text-decoration: underline;
	font-weight: inherit;
	letter-spacing: inherit;
}
.header-button-holder {
	margin-top: 10px;
}
.header-button-holder a {
	margin-top: 10px;
	margin-left: 6px;
	margin-right: 6px;
}

/**
 * Reaction Icons
 */

.impression-icon {
	background-image: url('../img/reactions/impression-icon.png');
	background-position: center;
	background-size: cover;
}
.reaction-a-icon {
	background-image: url('../img/reactions/reactions-a-icon.png');
	background-position: center;
	background-size: cover;
}
.reaction-b-icon {
	background-image: url('../img/reactions/reactions-b-icon.png');
	background-position: center;
	background-size: cover;
}
.reaction-c-icon {
	background-image: url('../img/reactions/reactions-c-icon.png');
	background-position: center;
	background-size: cover;
}
.reaction-d-icon {
	background-image: url('../img/reactions/reactions-d-icon.png');
	background-position: center;
	background-size: cover;
}
.reaction-e-icon {
	background-image: url('../img/reactions/reactions-e-icon.png');
	background-position: center;
	background-size: cover;
}

/**
 * Obfuscation Styling
 */

span.obfuscate,
span.highlight {
	display: unset;
	color: var(--wave-obfuscation-grey);
	background-color: var(--wave-obfuscation-grey);
	border-radius: 2px;
	-webkit-box-decoration-break: clone;
	-o-box-decoration-break: clone;
	box-decoration-break: clone;
}
span.highlight {
	background-color: yellow;
	color: unset;
}
h1 span.obfuscate,
h1 span.highlight {
	padding: 8px 6px 4px 6px;
}
h2 span.obfuscate,
h2 span.highlight {
	padding: 6px 5px 2px 5px;
}
p span.obfuscate,
p span.highlight {
	padding: 3px 5px 3px 5px;
}
span.obfuscate.content {
	color: var(--wave-obfuscation-grey-text);
}
