/*
 * File: auth.css
 * Project: our-wave-admin-app
 *
 * Created by Brendan Michaelsen on December 31, 2021 at 2:14 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: September 16, 2024 at 12:00 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Auth Styles
 */

.auth-container .instruction-holder {
	margin-bottom: 30px;
}
.auth-container .instruction-holder a,
.auth-container .instruction-holder a:link,
.auth-container .instruction-holder a:visited {
	text-transform: none;
	letter-spacing: normal;
	font-family: inherit;
	font-weight: inherit;
	text-decoration: underline;
}
.auth-container .instruction-holder h2 {
	font-family: 'Playfair Display', Georgia, Times, serif;
	font-weight: 900;
	color: var(--wave-darker-blue);
	line-height: 1.3;
}
.auth-container p {
	font-size: 1.2rem;
	font-weight: 400;
}
.auth-container .forgot-password-link {
	margin: 7px 0px 2px;
	text-align: right;
	width: 100%;
}
.auth-container .forgot-password-link a,
.auth-container .forgot-password-link a:link,
.auth-container .forgot-password-link a:visited {
	font-size: 0.8rem;
	text-transform: none;
	font-family: 'Outfit', Verdana, Arial, Helvetica, sans-serif;
	letter-spacing: normal;
	font-weight: 600;
}
.auth-container .login-link-section {
	margin: 12px auto 0px;
}
.auth-container .login-link-section a,
.auth-container .login-link-section a:link,
.auth-container .login-link-section a:visited {
	font-size: 0.8rem;
	text-transform: none;
	font-family: 'Outfit', Verdana, Arial, Helvetica, sans-serif;
	letter-spacing: normal;
	font-weight: 600;
}
.auth-arrow {
	color: var(--wave-lighter-blue);
}
.input-container {
	width: 100%;
	max-width: 400px;
	margin: 0px auto;
}
.code-input-container {
	display: flex;
	justify-content: space-between;
	margin: 20px 0px 20px;
	position: relative;
	z-index: 999;
	background-color: var(--wave-lighter-blue);
}
.code-input-container div {
	flex-basis: 15%;
	height: 74px;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14);
	padding: 10px 10px 10px 10px;
	border-radius: 4px;
	background-color: white;
}
.qr-code-container {
	width: 150px;
	height: 150px;
	margin: 0px auto;
	padding: 3px;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14);
	border-radius: 6px;
	background-color: white;
	overflow: hidden;
	margin-bottom: 30px;
}
.qr-code-container img {
	width: 100%;
	height: 100%;
}
.code-input-container div input {
	font-family: inherit !important;
	height: 100%;
	font-size: 2em;
	line-height: 1;
	color: var(--wave-darker-blue);
	outline: none;
	transition: all 0.2s ease-in-out;
	border: none;
	text-align: center;
	box-shadow: none;
	padding: 0px;
	margin: 0px;
	font-weight: 700 !important;
}
.code-input-container div input:focus {
	outline: none;
	border: none;
	box-shadow: none;
}
.authenticate-input input {
	font-size: 1.5em;
	line-height: 1;
	color: var(--wave-darker-blue);
	display: block;
	height: 67px;
	padding: 0px 20px;
	border-color: white;
	outline: 0;
	transition: all 0.2s ease-in-out;
	border-radius: 4px;
	border: none;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	text-align: center;
	margin: 0px;
	position: relative;
}
.authenticate-input input:focus,
.authenticate-input input:active {
	background-color: white;
	outline: 0;
	border: none;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
}
@media only screen {
	.auth-container {
		padding-top: 80px;
		padding-bottom: 100px;
	}
	.auth-spacer {
		height: 40px;
		width: 100%;
	}
	.auth-spacer.small {
		height: 20px;
		width: 100%;
	}
}
@media only screen and (min-width: 30.563em) {
	.auth-container {
		padding-top: 140px;
		padding-bottom: 100px;
	}
	.auth-spacer {
		height: 80px;
		width: 100%;
	}
	.auth-spacer.small {
		height: 40px;
		width: 100%;
	}
}
