/*
 * File: footer.css
 * Project: our-wave-admin-app
 *
 * Created by Brendan Michaelsen on December 31, 2021 at 2:14 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: September 16, 2024 at 10:37 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Footer Styles
 */

.footer-action-bar-section {
	padding-top: 26px;
	padding-bottom: 20px;
}
.share-footer {
	padding-top: 16px;
	padding-bottom: 16px;
}
.footer-action-bar-section h3 {
	color: white;
	font-size: 0.7rem;
	display: inline-block;
	margin: 10px 12px 10px 12px;
	font-family: 'Outfit', Verdana, Arial, Helvetica, sans-serif;
	font-weight: 600;
}
.footer-action-bar-section a,
.footer-action-bar-section a:link,
.footer-action-bar-section a:visited {
	color: white;
	text-decoration: underline;
	text-transform: none;
	font-size: 0.7rem;
	font-family: 'Outfit', Verdana, Arial, Helvetica, sans-serif;
	letter-spacing: normal;
	font-weight: 600;
}
.footer-action-bar-section a:hover,
.footer-action-bar-section a:active {
	opacity: 0.85;
}
.footer-action-bar-section .emphasize a {
	padding: 12px 10px 7px 10px;
	color: var(--wave-darker-blue);
	background-color: white;
	border-radius: 4px;
	text-decoration: none;
}
.footer-action-bar-section.transparent-section h3 {
	color: var(--wave-darker-blue);
	font-size: 0.7rem;
	display: inline-block;
	margin: 10px 12px 10px 12px;
	font-family: 'Outfit', Verdana, Arial, Helvetica, sans-serif;
	font-weight: 600;
}
.footer-action-bar-section.transparent-section a,
.footer-action-bar-section.transparent-section a:link,
.footer-action-bar-section.transparent-section a:visited {
	color: var(--wave-darker-blue);
	text-decoration: underline;
	text-transform: none;
	font-size: 0.7rem;
	font-family: 'Outfit', Verdana, Arial, Helvetica, sans-serif;
	letter-spacing: normal;
	font-weight: 600;
}
.footer-action-bar-section.transparent-section a:hover,
.footer-action-bar-section.transparent-section a:active {
	opacity: 0.85;
}
.footer-action-bar-section.transparent-section .emphasize a {
	padding: 12px 10px 7px 10px;
	color: var(--wave-lighter-blue);
	background-color: var(--wave-darker-blue);
	border-radius: 4px;
	text-decoration: none;
}
.full-footer-section {
	flex-shrink: 0;
}
