/*
 * File: header.css
 * Project: our-wave-admin-app
 *
 * Created by Brendan Michaelsen on December 31, 2021 at 2:14 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: September 16, 2024 at 10:37 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Header Styles
 */

@media only screen {
	.header-mobile-breakpoint-show {
		display: none;
	}
	.header-mobile-breakpoint-hide {
		display: block;
	}
	.primary-header-footer,
	.header-container {
		height: 78px !important;
	}
	.scrolling-header.minimize-header .primary-header-footer {
		height: 52px !important;
	}
	.scrolling-header.minimize-header .header-holder {
		padding-top: 12px !important;
		padding-bottom: 12px !important;
	}
	.header-content-section-padding {
		padding-top: 80px;
		padding-bottom: 70px;
	}
	.nav-dropdown {
		right: 5vw;
	}
	.header-holder {
		padding-top: 22px !important;
		padding-bottom: 22px !important;
	}
	.nav-container-left {
		padding-top: 32px;
	}
	.header-content-section h1 {
		font-size: 1.8rem;
	}
}
@media only screen and (min-width: 30.563em) {
	.primary-header-footer,
	.header-container {
		height: 90px !important;
	}
	.scrolling-header.minimize-header .primary-header-footer {
		height: 66px !important;
	}
	.scrolling-header.minimize-header .header-holder {
		padding-top: 12px !important;
		padding-bottom: 12px !important;
	}
	.scrolling-header.minimize-header .header-logo-holder {
		padding: 3px 0px;
	}
	.header-content-section-padding {
		padding-top: 8vw;
		padding-bottom: 10vw;
	}
	.nav-dropdown {
		right: 5vw;
	}
	.header-holder {
		padding-top: 24px !important;
		padding-bottom: 24px !important;
	}
	.nav-container-left {
		padding-top: 32px;
	}
	.header-content-section h1 {
		font-size: 2.5rem;
	}
}
@media only screen and (min-width: 40em) {
	.primary-header-footer,
	.header-container {
		height: 90px !important;
	}
	.nav-dropdown {
		right: 5vw;
	}
	.header-content-section h1 {
		font-size: 2.5rem;
	}
}
@media only screen and (min-width: 53.5em) {
	.header-mobile-breakpoint-show {
		display: initial;
	}
	.header-mobile-breakpoint-hide {
		display: none;
	}
}
@media only screen and (min-width: 57.563em) {
	.primary-header-footer,
	.header-container {
		height: 90px !important;
	}
	.nav-dropdown {
		right: 4vw;
	}
	.header-content-section h1 {
		font-size: 2.8rem;
	}
}
@media only screen and (min-width: 75.563em) {
	.primary-header-footer,
	.header-container {
		height: 90px !important;
	}
	.nav-dropdown {
		right: 4vw;
	}
}
.header-container {
	position: relative;
	z-index: 999;
}
.header {
	width: 100%;
	z-index: 999;
}
.scrolling-header .header {
	background-color: var(--wave-white) !important;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14);
}
.solid-header .header {
	background-color: var(--wave-lighter-blue);
}
.stationary {
	position: fixed !important;
}
.primary-header-footer {
	width: 100%;
	display: flex;
	align-items: center;
}
.header .logo {
	height: 100%;
}
.nav-container {
	float: right;
	height: 100%;
}
.primary-header-footer .navigation-link-icon {
	display: flex;
	align-items: center;
	margin-right: 15px;
}
.primary-header-footer .navigation-link-icon i {
	font-size: 1.3rem;
}
.nav-container a:hover,
.nav-container a:focus {
	color: var(--wave-darker-blue);
}
.nav-container .profile-image-container {
	width: 48px;
	height: 48px;
	border-radius: 50%;
	background-color: var(--wave-darker-blue);
	display: flex;
	justify-content: center;
	align-items: center;
}
.nav-container .profile-name {
	margin-left: 10px;
	position: relative;
	letter-spacing: normal;
}
.nav-container .profile-image-container span {
	font-family: 'Outfit', Verdana, Arial, Helvetica, sans-serif;
	position: relative;
	color: var(--wave-lighter-blue);
	font-size: 1.3rem;
	left: 0.5px;
	font-weight: 800;
}
.transparent-header .nav-container .profile-image-container span {
	color: var(--wave-lighter-blue);
}
.navigation-link {
	font-size: 12px;
	white-space: nowrap;
	opacity: 1;
	float: right;
	margin-right: 40px;
	height: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.navigation-link:hover,
.navigation-link a:hover {
	opacity: 0.75;
}
.navigation-button {
	font-size: 12px;
	white-space: nowrap;
	opacity: 1;
	float: right;
	height: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding-left: 30px;
	padding-right: 30px;
	border-radius: 4px;
}
.navigation-button.right {
	margin-left: 15px;
}
.navigation-button.solid {
	background-color: var(--wave-darker-blue);
	color: var(--wave-lighter-blue);
}
.transparent-header .navigation-button.solid {
	background-color: var(--wave-darker-blue);
	color: var(--wave-lighter-blue);
}
.scrolling-header .navigation-button.solid {
	color: white;
}
.navigation-button.outline {
	background-color: transparent;
	border: 2px solid var(--wave-darker-blue);
	color: var(--wave-darker-blue);
}
.navigation-button.solid:hover,
.navigation-button.solid:active {
	opacity: 0.85;
	color: var(--wave-lighter-blue) !important;
}
.transparent-header .navigation-button.solid:hover,
.transparent-header .navigation-button.solid:active {
	opacity: 0.85;
	color: var(--wave-lighter-blue) !important;
}
.scrolling-header .navigation-button.solid:hover,
.scrolling-header .navigation-button.solid:active {
	color: white !important;
}
.header-logo-holder {
	float: left;
	height: 100%;
	padding: 0px;
	flex-grow: 1;
}
.header-logo-holder img {
	height: 100%;
}
.navigation-link.no-margin {
	margin: 0px;
}
.impersonation-container {
	margin-left: 10px;
	position: relative;
	font-size: 0.9rem;
}

/**
 * Mobile Header Styles
 */

.menu-link a,
.menu-link a:link,
.menu-link a:visited {
	margin: 0px;
	font-size: 13px;
}
.menu-link a:hover,
.menu-link a:active {
	opacity: 1;
}
.nav-container.menu-link,
.menu-link .navigation-link {
	padding: 0px;
}
.nav-container .menu-arrow-container {
	width: 17px;
	height: 17px;
	margin-left: 8px;
	top: 0px;
	display: inline-block;
	position: relative;
}
.nav-container .menu-arrow {
	width: 100%;
	height: 100%;
	display: block;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
}
.nav-container .menu-arrow.open {
	background-position: center;
	background-size: cover;
	position: absolute;
	top: 0px;
	opacity: 1;
	width: 100%;
	height: 100%;
}
.nav-container .menu-arrow.open:before {
	position: relative;
	font-family: 'Font Awesome 5 Pro';
	top: -3px;
	left: 0px;
	content: '\f078';
	font-size: 1.15rem;
	font-weight: 400;
}
.nav-container .menu-arrow.close {
	background-position: center;
	background-size: cover;
	position: absolute;
	top: 0px;
	opacity: 1;
	width: 100%;
	height: 100%;
}
.nav-container .menu-arrow.close:before {
	position: relative;
	font-family: 'Font Awesome 5 Pro';
	top: -2px;
	left: 0px;
	content: '\f077';
	font-size: 1.15rem;
	font-weight: 400;
}
.nav-container .menu-arrow.hide {
	opacity: 0;
}
.menu-arrow.inline {
	width: 15px !important;
	height: 15px !important;
	right: -20px;
	top: -6px !important;
	cursor: pointer;
}
.menu-arrow.inline:before {
	font-size: 1.05rem !important;
}
.nav-dropdown {
	position: absolute;
	background-color: white;
	min-width: 190px;
	box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.22);
	z-index: 99 !important;
	top: 40px;
	height: 0px;
	border-radius: 3px;
	overflow: hidden;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	padding: 0px 12px 0px 12px;
}
.nav-dropdown.mobile {
	top: 83px;
	max-width: 430px;
	width: 90%;
}
.nav-dropdown .nav-left {
	float: left;
	width: 50%;
}
.nav-dropdown .nav-right {
	float: right;
	width: 46%;
}
.nav-dropdown .nav-left :last-child,
.nav-dropdown .nav-right :last-child {
	margin-bottom: 12px;
}
.nav-dropdown.mobile.show {
	height: 480px;
	overflow: scroll;
}
.nav-dropdown p {
	font-family: 'Outfit', Verdana, Arial, Helvetica, sans-serif;
	letter-spacing: 0.5px;
	color: var(--wave-grey-text);
	font-size: 11px;
	padding: 16px 8px 0px 8px;
	margin-bottom: 4px;
	opacity: 0.7;
	text-transform: uppercase;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-weight: 800;
}
.nav-dropdown a {
	padding: 10px 10px 10px 10px;
	border-radius: 3px;
	display: block;
	color: var(--wave-darker-blue);
	background-color: white;
	font-size: 0.64rem;
	font-weight: 700;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	min-width: 100%;
	margin: 0px;
}
.nav-dropdown a i {
	font-size: 13px;
	margin: 0px 5px 0px 0px !important;
	position: relative;
	top: 1px;
}
.nav-dropdown a i.outlink {
	margin-left: 8px !important;
	font-size: 0.7rem;
	top: -1px;
	position: relative;
	display: none;
}
.nav-dropdown .sub-navigation a {
	padding: 12px 10px 8px 10px;
}
.nav-dropdown .sub-navigation.tags {
	margin-left: 22px;
}
.nav-dropdown .sub-navigation.feedback {
	margin-left: 19px;
}
.nav-dropdown .sub-navigation.tags.active {
	margin-top: 0px;
	height: 66px;
}
.nav-dropdown .sub-navigation.feedback.active {
	margin-top: 0px;
	height: 66px;
}
@media only screen and (min-width: 26em) {
	.nav-dropdown a i {
		font-size: 16px;
		margin: 0px 10px 0px 0px !important;
		top: 2px;
	}
	.nav-dropdown a {
		font-size: 0.7rem;
	}
	.nav-dropdown a i.outlink {
		display: inline;
	}
	.nav-dropdown .sub-navigation.tags {
		margin-left: 31px;
	}
	.nav-dropdown .sub-navigation.feedback {
		margin-left: 27px;
	}
	.nav-dropdown .sub-navigation.tags.active {
		margin-top: 0px;
		height: 68px;
	}
	.nav-dropdown .sub-navigation.feedback.active {
		margin-top: 0px;
		height: 68px;
	}
}
.nav-dropdown a:link,
.nav-dropdown a:visited {
	color: var(--wave-darker-blue);
}
.nav-dropdown a.destructive {
	color: var(--wave-red);
}
.nav-dropdown a:hover,
.nav-dropdown a.active {
	background-color: var(--wave-light-grey) !important;
	opacity: 1;
}
