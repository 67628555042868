/*
 * File: input.css
 * Project: our-wave-admin-app
 *
 * Created by Brendan Michaelsen on December 31, 2021 at 2:14 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: September 16, 2024 at 11:14 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Placeholder Styles
 */

::-webkit-input-placeholder {
	/* WebKit, Blink, Edge */
	color: var(--wave-placeholder-text) !important;
}
:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	color: var(--wave-placeholder-text) !important;
	opacity: 1;
}
::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	color: var(--wave-placeholder-text) !important;
	opacity: 1;
}
:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: var(--wave-placeholder-text) !important;
}

/**
 * Base Input Styles
 */

textarea {
	font-size: 1.05em;
	line-height: 1.6;
	color: var(--wave-darker-blue);
	display: block;
	height: 67px;
	padding: 24px 24px 20px 24px;
	border-color: white;
	outline: 0;
	transition: all 0.2s ease-in-out;
	border-radius: 4px;
	border: none;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	text-align: left;
	margin: 0px;
}
textarea:focus,
textarea:active {
	background-color: white;
	outline: 0;
	border: none;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
}
.smart-fields-section fieldset {
	padding: 0;
	border: 0;
	position: relative;
	margin: 15px auto 0px;
}
.mce-container {
	margin: 15px auto 0px;
}
.field-dropdown {
	position: absolute;
	background-color: var(--wave-white);
	box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.22);
	width: 265px;
	border-radius: 4px;
	margin-top: 5px;
	max-height: 200px;
	top: 42px;
	overflow: scroll;
}
.field-dropdown p {
	background-color: var(--wave-white);
	padding: 8px 8px;
	font-size: 0.9rem;
	font-weight: 600;
	cursor: pointer;
	margin: 0px 10px;
	border-radius: 4px;
}
.field-dropdown p:hover {
	background-color: var(--wave-light-grey);
}
.field-dropdown p:first-of-type {
	margin-top: 10px;
}
.field-dropdown p:last-of-type {
	margin-bottom: 10px;
}

/**
 * Smart Input Styles
 */

.smart-fields-section fieldset .input-label {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.smart-fields-section input,
.smart-fields-section textarea,
.smart-fields-section select {
	font-size: 1.15em;
	line-height: 1;
	color: var(--wave-darker-blue);
	display: block;
	height: 67px;
	padding: 25px 16px 20px 16px;
	border-color: white;
	outline: 0;
	transition: all 0.2s ease-in-out;
	border-radius: 4px;
	border: none;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14);
	text-align: left;
	padding-left: 18px !important;
	padding-right: 18px !important;
	margin: 0px;
}
.smart-fields-section textarea {
	padding-top: 32px;
}
.smart-fields-section select {
	/* Needs to be an explicit icon (before doesn't work on select elements) */
	margin: 0px;
	-moz-appearance: none;
	-webkit-appearance: none;
	background: url('../../../img/icons/filter-arrow.png');
	background-size: 12px 12px;
	background-repeat: no-repeat;
	background-position-x: 96%;
	background-position-y: center;
	cursor: pointer;
	background-color: white;
}
.smart-fields-section select::-ms-expand {
	display: none;
}
.smart-fields-section select:required:invalid {
	color: #c3c3c3;
	outline: 0;
}
.smart-fields-section select.label {
	padding: 25px 16px 10px;
}
.smart-fields-section input:focus,
.smart-fields-section input:active,
.smart-fields-section textarea:focus,
.smart-fields-section textarea:active,
.smart-fields-section select:focus,
.smart-fields-section select:active {
	background-color: white;
	outline: 0;
	border: none;
}
.smart-fields-section input[data-empty='false'],
.smart-fields-section input:valid,
.smart-fields-section input:focus,
.smart-fields-section input:disabled,
.smart-fields-section input:-webkit-autofill,
.smart-fields-section textarea[data-empty='false'],
.smart-fields-section textarea:valid,
.smart-fields-section textarea:focus,
.smart-fields-section textarea:disabled {
	outline: 0;
	padding: 25px 16px 10px;
	border: none;
}
.smart-fields-section input:focus,
.smart-fields-section textarea:focus {
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.14);
}
.smart-fields-section input[data-empty='false'] + label,
.smart-fields-section input:valid + label,
.smart-fields-section input:focus + label,
.smart-fields-section input:disabled + label,
.smart-fields-section input:-webkit-autofill + label,
.smart-fields-section textarea[data-empty='false'] + label,
.smart-fields-section textarea:valid + label,
.smart-fields-section textarea:focus + label,
.smart-fields-section textarea:disabled + label,
.smart-fields-section label.focus {
	font-weight: 800 !important;
	font-size: 11.5px;
	left: 0;
	right: 0;
	margin: auto;
	transform: translate3d(0, -8px, 0);
	-webkit-transform: translate3d(0, -8px, 0);
}
.smart-fields-section input:disabled {
	background-color: white;
}
.smart-fields-section label {
	position: absolute;
	font-weight: 700;
	top: 17px;
	color: var(--wave-placeholder-text);
	transform: translate3d(0, 0, 0);
	-webkit-transform: translate3d(0, 0, 0);
	transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	cursor: text;
	font-size: 1.15em;
	left: 0;
	right: 0;
	margin: auto;
	text-align: left;
	padding-left: 18px;
}

/**
 * Input Container Styling
 */

.input-container-with-error {
	position: relative;
}
.input-container-with-error.restrict-width {
	width: 82%;
	margin: 0px auto;
}
.input-container-with-error button {
	margin: 0px !important;
	position: relative;
	z-index: 999;
	width: 100%;
}
.input-alert-container {
	width: 100%;
	position: absolute;
	opacity: 0;
}
.input-alert-inner {
	max-width: 100%;
	font-family: 'Outfit', Verdana, Arial, Helvetica, sans-serif;
	font-weight: 500 !important;
	background-color: white;
	font-size: 0.97em;
	line-height: 1.6;
	color: var(--wave-darker-blue);
	display: inline-block;
	padding: 16px 24px 16px 24px;
	border-color: white;
	outline: 0;
	border-radius: 4px;
	border: none;
	text-align: center;
	margin: 0px auto;
	transition: all 0.3s;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-o-transition: all 0.3s;
}
.input-alert-inner.shadow {
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.12);
}

/**
 * Toggle Input Styling
 */

.toggle-section {
	padding: 15px 0px 0px 0px;
}
.toggle-row {
	margin: 15px 0px 0px 0px;
	display: inline-block;
	width: 100%;
}
.custom-switch {
	float: left;
	position: relative;
	user-select: none;
}
.custom-switch input {
	display: none !important;
}
.custom-switch label {
	margin: 0px 18px 0px 0px !important;
	position: relative;
	padding: 0px !important;
	width: 78px;
	height: 38px;
	border-radius: 4px;
	border: none;
	background: var(--wave-button-grey);
	cursor: pointer;
	display: inline-block;
	transition: all 0.4s ease-in-out;
	transform: none !important;
	-webkit-transform: none !important;
	top: 0px !important;
}
.custom-switch label:after {
	position: relative;
	display: block;
	content: '';
	width: 32px;
	height: 30px;
	top: 4px;
	left: 4px;
	border-radius: 3px;
	background: var(--wave-white);
	pointer-events: none;
	transition: all 0.4s ease-in-out;
}
.custom-switch input:checked + label {
	background: var(--wave-darker-blue);
}
.custom-switch input:disabled + label {
	opacity: 0.7;
	cursor: not-allowed;
}
.custom-switch input:checked + label:after {
	left: 42px;
	background: var(--wave-white);
}
.custom-checkbox-content {
	display: table-cell;
	text-align: left;
	position: relative;
	top: -5px;
}
.custom-checkbox-content p {
	margin: 0px;
	font-size: 0.95rem !important;
	color: var(--wave-darker-blue);
	font-weight: 600 !important;
}

/**
 * Checkbox Input Styling
 */

.options-container {
	padding: 0px 0px 40px;
	display: inline-block;
}
.options-row {
	margin: 20px 0px 0px;
}
.custom-checkbox {
	width: 27px;
	height: 27px;
	background-color: transparent;
	border-radius: 4px;
	outline: 0;
	border: solid 2px var(--wave-darker-blue);
	display: inline-block;
	float: left;
	margin-right: 17px;
	cursor: pointer;
}
.custom-checkbox:focus,
.custom-checkbox:active,
.custom-checkbox.active {
	outline: 0;
	border: solid 2px var(--wave-darker-blue);
	background-position: center;
	background-size: cover;
	background-color: var(--wave-darker-blue);
	text-align: center;
}
.custom-checkbox.white:focus,
.custom-checkbox.white:active,
.custom-checkbox.white.active {
	outline: 0;
	border: solid 2px var(--wave-darker-blue);
	background-position: center;
	background-size: cover;
	background-color: var(--wave-darker-blue);
	text-align: center;
	color: var(--wave-white);
}
.custom-checkbox:focus::before,
.custom-checkbox:active::before,
.custom-checkbox.active::before {
	position: relative;
	font-family: 'Font Awesome 5 Pro';
	top: -1px;
	left: 0px;
	content: '\f00c';
	font-size: 0.9rem;
	font-weight: 400;
}
.custom-checkbox.white:focus::before,
.custom-checkbox.white:active::before,
.custom-checkbox.white.active::before {
	position: relative;
	font-family: 'Font Awesome 5 Pro';
	top: -1px;
	left: 0px;
	content: '\f00c';
	font-size: 0.9rem;
	font-weight: 400;
}
.custom-checkbox-content p a,
.custom-checkbox-content p a:link,
.custom-checkbox-content p a:visited {
	text-decoration: underline;
}
