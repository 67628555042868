/*
 * File: colors.css
 * Project: our-wave-admin-app
 *
 * Created by Brendan Michaelsen on December 31, 2021 at 2:14 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: September 16, 2024 at 10:37 AM
 * Modified By: Brendan Michaelsen
 */

:root {
	--wave-white: white;
	--wave-grey-text: #717274;
	--wave-placeholder-text: #c3c3c3;
	--wave-light-grey: #f5f5f5;
	--wave-button-grey: #c0c1c4;
	--wave-skeleton-grey: #efefef;
	--wave-input-grey: #c5c5c5;
	--wave-background-grey: #dee5ea;
	--wave-obfuscation-grey: #e7e7e7;
	--wave-obfuscation-blue: #cfc5aa;
	--wave-obfuscation-grey-text: #9f9f9f;
	--wave-obfuscation-blue-text: #7a8d97;
	--wave-darker-blue: #44566c;
	--wave-dark-blue: #4d708c;
	--wave-blue: #9ebed8;
	--wave-light-blue: #c6d8e5;
	--wave-lighter-blue: #f0f5f7;
	--wave-border-blue: #c7d6e4;
	--wave-yellow-gradient-top: rgba(243, 211, 168, 1);
	--wave-yellow-gradient-bottom: rgba(246, 234, 188, 1);
	--wave-yellow-gradient-button: #f6e0b4;
	--wave-orange: #edbf83;
	--wave-yellow: #f2e2a0;
	--wave-red: #ea526f;
	--wave-green: #33ce75;
	--wave-table-border: #dbd9d2;
}
