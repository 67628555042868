/*
 * File: modal.css
 * Project: our-wave-admin-app
 *
 * Created by Brendan Michaelsen on December 31, 2021 at 2:14 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: September 16, 2024 at 10:37 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Modal Styles
 */

.modal {
	position: fixed;
	z-index: 99999;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	opacity: 0;
	visibility: hidden;
	transform: scale(1.1);
	transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
	overflow: scroll;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 15px 0px;
}
.modal-inner {
	background-color: var(--wave-white);
	width: 100%;
	margin: auto auto;
	max-width: 540px;
	border-radius: 8px;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.22);
}
.modal-inner.light-blue {
	background-color: var(--wave-lighter-blue);
}
.modal-inner.large {
	max-width: 600px;
}
.modal-content {
	padding: 18px 40px 18px 40px;
}
.show-modal {
	opacity: 1;
	visibility: visible;
	transform: scale(1);
	transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}
.modal-inner .solid {
	width: 100%;
	box-shadow: none;
	border-radius: 0px;
}
.modal-inner button.solid {
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	padding-left: 0px;
	padding-right: 0px;
}
.modal-inner button.left-button {
	width: 50%;
	display: inline-block;
	border-bottom-right-radius: 0px;
}
.modal-inner button.right-button {
	width: 50%;
	float: right;
	border-bottom-left-radius: 0px;
}
.modal-inner .grey-button {
	background-color: var(--wave-button-grey);
	border: 2px solid var(--wave-button-grey);
}
.modal-inner.full {
	max-width: unset;
	width: 90%;
	height: 90%;
	position: relative;
	display: flex;
	flex-direction: column;
}
.modal-inner.full.preview {
	width: 95%;
	height: 90%;
}
@media only screen and (min-width: 60em) {
	.modal-inner.full.preview {
		width: 70%;
		height: 90%;
	}
}
.modal-inner.full.page {
	width: 95%;
}
.modal-inner.full.review {
	width: 100%;
	height: 100%;
}
.modal-inner.scroll {
	overflow: scroll;
}
.modal-inner.full.review .canvas-padding {
	padding: 15px 10px 10px;
	display: flex;
	height: 100%;
}
.modal-inner .close-button,
.modal-inner .complete-button {
	background-color: var(--wave-white);
	width: 34px;
	height: 34px;
	border-radius: 50%;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
	top: 15px;
	color: var(--wave-darker-blue);
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.3rem;
	position: absolute;
}
.modal-inner .close-button {
	left: 15px;
}
.modal-inner .complete-button {
	right: 15px;
	font-size: 1.1rem;
}
.modal-inner .close-button:hover,
.modal-inner .complete-button:hover {
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.15);
}
.modal-inner .close-button i,
.modal-inner .complete-button i {
	margin: 2px 0px 0px 1px;
}
.modal-content h1 {
	font-size: 1.9rem;
	text-align: center;
	margin-top: 15px;
}
.modal-inner h3 {
	color: var(--wave-darker-blue);
	font-weight: 700;
	text-align: center;
	font-size: 0.7rem;
	margin: 19px 0px 14px;
	text-transform: uppercase;
}
.modal-inner.full h3 {
	font-size: 0.8rem;
	margin: 27px 0px 0px;
}
.modal-inner h3 i {
	color: var(--wave-darker-blue);
	margin-right: 7px;
}
.modal-content p {
	text-align: center;
}
.modal-inner .divider-line {
	height: 1px;
	background-color: var(--wave-grey-text);
	opacity: 0.2;
}
.modal-inner .input-alert-container {
	text-align: center;
}
.modal-inner .input-alert-inner {
	border: 1px solid var(--wave-darker-blue);
}
.modal-inner .input-alert-inner.shadow {
	box-shadow: none !important;
}
.modal-inner .input-container-with-error {
	margin-left: 25px;
	margin-right: 25px;
}
.modal-inner .input-container-with-error.margin-offset {
	margin-top: -15px;
}
.modal-input-container {
	margin: 10px 0px 35px;
	background-color: white;
	position: relative;
	z-index: 99;
}
.modal-input-container input,
.modal-input-container select,
.modal-input-container textarea {
	box-shadow: none !important;
	font-size: 1em;
	line-height: 1;
	color: var(--wave-darker-blue);
	display: block;
	height: 57px;
	padding: 12px 16px 12px 16px;
	outline: 0;
	transition: all 0.2s ease-in-out;
	border-radius: 4px;
	border: 1px solid var(--wave-button-grey);
	text-align: left;
	padding-left: 18px !important;
	padding-right: 18px !important;
	margin: 0px;
	background-color: white;
}
.modal-input-container textarea {
	min-height: 100px;
	height: 100px;
	line-height: 1.4;
	min-width: 100%;
	max-width: 100%;
}
.modal-input-container input:focus,
.modal-input-container input:hover,
.modal-input-container input:active,
.modal-input-container input:valid,
.modal-input-container textarea:focus,
.modal-input-container textarea:hover,
.modal-input-container textarea:active,
.modal-input-container textarea:valid,
.modal-input-container select:focus,
.modal-input-container select:hover,
.modal-input-container select:active,
.modal-input-container select:valid {
	box-shadow: none !important;
	border: 1px solid var(--wave-button-grey) !important;
}
.modal-input-container input.centered-input {
	text-align: center !important;
}
.modal-input-container input.left-input {
	text-align: left !important;
}
.modal-input-container input.large-input {
	font-size: 1.6rem;
}
.modal-input-container .field-clear {
	display: flex;
}
.modal-input-container .left-field {
	width: 49%;
	margin-left: 0px;
}
.modal-input-container .right-field {
	width: 49%;
	margin-right: 0px;
}
.modal-input-container .full-field {
	width: 100%;
}
.modal-input-container .no-margin {
	margin-top: 0px;
}
.modal-input-container label {
	font-size: 1em;
	top: 15px;
}
.modal .field-dropdown {
	position: absolute;
	background-color: var(--wave-white);
	box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.22);
	width: 100%;
	border-radius: 4px;
	margin-top: 5px;
	max-height: 200px;
	top: 42px;
	overflow: scroll;
}
.modal .field-dropdown p {
	background-color: var(--wave-white);
	padding: 8px 8px;
	font-size: 0.9rem;
	font-weight: 800;
	cursor: pointer;
	margin: 0px 10px;
	border-radius: 4px;
}
.modal .field-dropdown p:hover {
	background-color: var(--wave-light-grey);
}
.modal .field-dropdown p:first-of-type {
	margin-top: 10px;
}
.modal .field-dropdown p:last-of-type {
	margin-bottom: 10px;
}
.modal .field-dropdown p.create {
	color: var(--wave-darker-blue);
	font-weight: 800;
}
.tox-tinymce-aux {
	z-index: 999999 !important;
}
.tox-tinymce {
	border-radius: 4px !important;
	border: 1px solid var(--wave-button-grey) !important;
}
